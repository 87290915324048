import { FC } from 'react';
import Head from 'next/head';
import Image from 'next/image';

interface IFacebookPixelProps {
  pixelId: string[];
}

const FacebookPixel: FC<IFacebookPixelProps> = ({ pixelId }) => {

  const script = pixelId?.map((id) => `
  !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${id}');
              fbq('track', 'PageView');
`).join('');


  return (
    <>
      <Head>
        <script
        
          dangerouslySetInnerHTML={{
            __html: script
          }}
        />
        <noscript>
          <Image
            alt='facebook-pixel'
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
          />
        </noscript>
      </Head>
    </>
  );
};

export default FacebookPixel;
