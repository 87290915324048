import "../styles/globals.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@/lib/theme";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { metropolis } from "../lib/font";
import { initGA, logPageView } from "../analytics";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { FaWhatsapp } from "react-icons/fa";
import FacebookPixel from "./component/FacebookPixel";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GTM from "../pages/gtm/GTM";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, 
    },
  },
});

type AppProps = {
  Component: React.ElementType;
  pageProps: Record<string, unknown>;
};

export default function App({ Component, pageProps }: AppProps) {
  const navigation = useRouter();
  const isNotCartPage = navigation.pathname !== "/cart";
  useEffect(() => {
    if (!(window as any).GA_INITIALIZED) {
      initGA();
      (window as any).GA_INITIALIZED = true;
    }
    logPageView();
  }, []);
  const isNotWebView = navigation.query.webview !== "true";
  const isNotBioForm = navigation.pathname !== "/biologicalAgeCalculator"
  const pixelIds = ["1738933153276643", "244798824797743"];

  return (
    <main className={`${metropolis.className}`}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || "74216840596-v7t2soa1neu4amv6aohgu198096bcap2.apps.googleusercontent.com"}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ChakraProvider theme={theme}>
            {isNotCartPage && isNotWebView && isNotBioForm && (
              <div className="w-[55%] md:w-[20%] p-2 bg-gray-800 fixed bottom-20 md:bottom-10 right-2 md:right-10 z-50 rounded-lg text-sm mr-4 animate-pulse">  
                <a
                  href="https://wa.me/+919108713102"
                  title="whatsapp-logo"
                  target="_blank"
                  className="flex flex-row justify-center items-center"
                >
                  <span className="text-white text-sm md:text-lg font-bold mb-2 mr-3">Talk to our expert</span>
                  <FaWhatsapp className="ml-3 text-white text-5xl bg-green-500 rounded-full p-3 hover:bg-green-600 cursor-pointer" />
                </a>
              </div>
            )}
            <FacebookPixel pixelId={pixelIds} />
            <GTM containerId="GTM-TJHFQ7S" />
            <Component {...pageProps} />
          </ChakraProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </main>
  );
}